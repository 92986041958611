<template>
  <div>
    <!-- <v-overlay :value="!loaded">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <v-btn
      color="primary"
      :loading="loadingPdf"
      :disabled="!loaded"
      class="mb-2"
      @click="printPdf()"
      >Download PDF</v-btn
    >

    <v-sheet elevation="1" width="1063" style="padding: 96px;">
      <v-progress-linear
        v-if="!loaded"
        color="error"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <div v-if="appointment && patient" ref="patientPDF" id="patientPDF">
        <div class="html2pdf__page-break">
          <v-row>
            <v-col>
              <div class="text-subtitle text-md-subtitle">
                <span class="font-weight-bold">Patient:</span>
                {{ patient.fname }}
                {{ patient.lname }}
              </div>

              <div class="text-subtitle text-md-subtitle">
                <span class="font-weight-bold">Birthdate:</span> {{ birthdate }}
              </div>
              <div class="text-subtitle text-md-subtitle">
                <span class="font-weight-bold">Phone Number:</span>
                {{ phoneNumber }}
              </div>
              <div class="text-subtitle text-md-subtitle">
                <span class="font-weight-bold">Email:</span> {{ email }}
              </div>
            </v-col>
            <v-col class="d-flex flex-column align-end">
              <div>
                <div class="text-subtitle text-md-subtitle">
                  <span class="font-weight-bold">Visit Date:</span>
                  {{ visitDate }}
                </div>
                <div class="text-subtitle text-md-subtitle">
                  <span class="font-weight-bold">Physician:</span>
                  {{ doctorName }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4">
            Vitals
          </div>
          <v-row id="vitals">
            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="red" class="mr-2"
                  >mdi-heart-pulse</v-icon
                >
                <div v-if="appointment.vitals.pulse !== null">
                  {{ appointment.vitals.pulse }}
                  <span class="caption">bpm</span>
                </div>
                <div v-else>--</div>
              </div>
            </v-col>

            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="blue" class="mr-2"
                  >mdi-battery-heart-outline</v-icon
                >
                <div v-if="appointment.vitals.pulseox !== null">
                  {{ appointment.vitals.pulseox }}
                  <span class="caption">%</span>
                </div>
                <div v-else>--</div>
              </div>
            </v-col>

            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="pink" class="mr-2">mdi-lungs</v-icon>
                <div v-if="appointment.vitals.resprate !== null">
                  {{ appointment.vitals.resprate }}
                  <span class="caption">bths/min</span>
                </div>
                <div v-else>--</div>
              </div>
            </v-col>

            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="red" class="mr-2"
                  >mdi-thermometer</v-icon
                >
                <div v-if="appointment.vitals.temperature !== null">
                  {{ appointment.vitals.temperature }}
                  <span class="caption">&#176; F</span>
                </div>
                <div v-else>--</div>
              </div>
            </v-col>

            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="red" class="mr-2">mdi-heart</v-icon>
                <div
                  v-if="
                    appointment.vitals.systolic !== null &&
                      appointment.vitals.diastolic !== null &&
                      appointment.vitals.systolic &&
                      appointment.vitals.diastolic
                  "
                >
                  {{ appointment.vitals.systolic }} <span>/</span>
                  {{ appointment.vitals.diastolic }}
                  <span class="caption">mmHg</span>
                </div>

                <div v-else>--</div>
              </div>
            </v-col>

            <v-col :cols="$mq === 'mobile' ? '6' : ''">
              <div class="d-flex">
                <v-icon x-small color="orange" class="mr-2"
                  >mdi-emoticon-happy-outline</v-icon
                >
                <div v-if="appointment.vitals.painscale !== null">
                  {{ appointment.vitals.painscale }}
                </div>
                <div v-else>--</div>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4">
            Notes
          </div>
          <div v-if="examNotes" v-html="examNotes.notes"></div>
        </div>

        <!-- medical history -->
        <div class="html2pdf__page-break">
          <div
            class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4 mb-2"
          >
            Medical History
          </div>
          <div
            id="medicalHistory"
            v-if="
              medicalHistory &&
                appointment.medicalHistory.length > 0 &&
                appointment.medicalHistoryQuestions
            "
            class="black--text"
          >
            <v-data-table
              dense
              :headers="headers"
              :items="medicalHistory"
              item-key="index"
              class="elevation-1"
              :items-per-page="itemsPerPage"
              hide-default-footer
            ></v-data-table>
          </div>
        </div>
        <!-- end medical history -->

        <!-- symptoms -->
        <div class="html2pdf__page-break">
          <div
            class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4 mb-2"
          >
            Symptoms
          </div>
          <div id="symptoms" v-if="symptoms.length > 0" class="black--text">
            <v-data-table
              dense
              :headers="headers2"
              :items="symptoms"
              item-key="index"
              class="elevation-1"
              :items-per-page="itemsPerPage"
              hide-default-footer
            ></v-data-table>
          </div>
          <v-card-text v-else>No symptoms</v-card-text>
        </div>
        <!-- end symptoms -->

        <Documents
          :patientId="patientId"
          :uniqueName="uniqueName"
          :practiceId="practiceId"
        />

        <Medications
          :patientId="patientId"
          :uniqueName="uniqueName"
          :practiceId="practiceId"
        />

        <HealthData
          class="mb-8"
          :patientId="patientId"
          :uniqueName="uniqueName"
          :practiceId="practiceId"
        />
        <v-card-title>
          Echo Recordings
        </v-card-title>
        <div v-if="allRecordings.length > 0">
          <div v-for="item in allRecordings" :key="item.index">
            <div
              :class="
                allRecordings.length % item.index == 0
                  ? 'html2pdf__page-break'
                  : ''
              "
            >
              <v-card-subtitle>{{ item.fileName }}</v-card-subtitle>
              <AudioRecordings :selectedDocument="item" style="width: 100%;" />
            </div>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import * as fb from '@/firebase'
import moment from 'moment'
import Documents from '@/components/pdf/Documents'
import Medications from '@/components/pdf/Medications'
import HealthData from '@/components/pdf/HealthData'
import AudioRecordings from '@/components/pdf/AudioRecordings'
import ProxyLayout from '@/layouts/ProxyLayout'
import html2pdf from 'html2pdf.js'
import { eventBus } from '@/main.js'
import { saveAs } from 'file-saver'

export default {
  name: 'PatientPdf',
  props: ['user'],
  components: {
    Documents,
    Medications,
    HealthData,
    AudioRecordings
  },
  data() {
    return {
      currentUser: null,
      loaded: false,
      loadingPdf: false,
      patientId: null,
      practiceId: null,
      uniqueName: null,
      patient: null,
      appointment: null,
      doctor: null,
      visitDate: '',
      birthdate: '',
      phoneNumber: '',
      email: '',
      doctorName: '',
      examNotes: null,
      symptoms: [],
      recordings: [],
      headers2: [
        {
          text: 'Symptom',
          align: 'start',
          value: 'symptom'
        },
        { text: 'Category', value: 'category' }
      ],
      medicalHistory: [],
      headers: [
        {
          text: 'Question',
          align: 'start',
          sortable: false,
          value: 'question'
        },
        { text: 'Answer', value: 'answer' }
      ],
      itemsPerPage: 100,
      element: '',
      allRecordings: []
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.$emit(`update:layout`, ProxyLayout)
        }
      }
    },
    recordings: function() {
      this.allRecordings = []
      let count = 0

      for (const item of this.recordings) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'audio'
        })
        count++
      }

      this.allRecordings.sort((a, b) => (a.order > b.order ? 1 : -1))
      let count2 = 0
      for (const item of this.allRecordings) {
        item.index = count2
        count2++
      }
    },
    appointment: async function() {
      if (
        this.appointment.medicalHistory &&
        this.appointment.medicalHistoryQuestions
      ) {
        this.medicalHistory = await this.appointment.medicalHistoryQuestions.map(
          (x, i) => {
            return { question: x, answer: this.appointment.medicalHistory[i] }
          }
        )
      }
    },
    '$route.params': {
      immediate: true,
      handler() {
        if (this.$route.params.patientId)
          this.patientId = this.$route.params.patientId
        if (this.$route.params.practiceId)
          this.practiceId = this.$route.params.practiceId
        if (this.$route.params.uniqueName)
          this.uniqueName = this.$route.params.uniqueName
      }
    }
  },
  created() {
    eventBus.$on('ecgLoaded', () => {
      this.loaded = true
    })
  },
  async mounted() {
    if (this.patientId && this.practiceId && this.uniqueName) {
      await this.$bind(
        'patient',
        fb.db.doc(`/Patients/${this.patientId}`)
      ).then(() => {
        this.birthdate = this.patient.medicalcard.birthdate
          ? moment(this.patient.medicalcard.birthdate.toDate()).format('LL')
          : '--'

        this.phoneNumber = this.patient.phoneNumber
          ? this.patient.phoneNumber
          : '--'

        this.email = this.patient.email ? this.patient.email : '--'
      })

      await this.$bind(
        'examNotes',
        fb.db.doc(`Patients/${this.patientId}/Exam_Notes/${this.uniqueName}`)
      )

      await this.$bind(
        'appointment',
        fb.db.doc(
          `/Practices/${this.practiceId}/Appointments/${this.uniqueName}`
        )
      ).then(() => {
        this.visitDate = moment(this.appointment.startTime.toDate()).format(
          'LLL'
        )
      })
      await this.$bind(
        'recordings',
        fb.db.collection(
          `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Recordings`
        )
      )
      this.$bind(
        'doctor',
        fb.db.doc(`/Proxies/${this.appointment.doctorId}`)
      ).then(() => {
        this.doctorName = this.doctor.displayName
      })

      await fb.db
        .collection(
          `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Symptoms`
        )
        .get()
        .then(symptoms => {
          this.symptoms = []
          for (const item of symptoms.docs) {
            const data = item.data()
            for (const key of Object.keys(data)) {
              if (data[key]) {
                this.symptoms.push({
                  category: item.id,
                  symptom: key
                })
              }
            }
          }
        })
    }
  },

  methods: {
    async printPdf() {
      this.loadingPdf = true
      window.scrollTo(0, document.body.scrollHeight)
      window.scrollTo(0, 0)
      var opt = {
        margin: 0.5,
        filename: `${this.patient.fname}-${
          this.patient.lname
        }-${moment().format('MM-DD-YYYY')}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 96,
          letterRendering: true,
          useCORS: true,
          scale: 0.9
        },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' }
      }
      this.element = document.getElementById('patientPDF')

      let file1 = await html2pdf()
        .set(opt)
        .from(this.element)
        .toPdf()
        .output('blob')
        .then(data => {
          return data
        })
      this.saveZipOne(file1)
      this.loadingPdf = false
    },
    saveZipOne(file1) {
      saveAs(
        file1,
        `${this.patient.fname}.${this.patient.lname}.${
          this.patient.medicalcard.birthdate
            ? moment(this.patient.medicalcard.birthdate.toDate()).format(
                'MM-DD-YYYY'
              )
            : 'na'
        }.pdf`
      )
    }
  }
}
</script>
