<template>
  <div>
    <div style="position: relative;">
      <canvas
        v-show="false"
        id="ecg1"
        ref="ecg1"
        style="max-height: 200px !important; "
      >
      </canvas>
      <img
        id="ecg1"
        v-if="imgUrl1"
        style="width: 100%; height: auto;"
        :src="imgUrl1"
        alt=""
      />
      <slot></slot>
    </div>
    <div style="position: relative; ">
      <canvas
        v-show="false"
        id="ecg2"
        ref="ecg2"
        style="max-height: 200px !important; "
      >
      </canvas>
      <img
        id="ecg2"
        v-if="imgUrl2"
        style="width: 100%; height: auto;"
        :src="imgUrl2"
        alt=""
      />
      <slot></slot>
    </div>
    <div style="position: relative; ">
      <canvas
        v-show="false"
        id="ecg3"
        ref="ecg3"
        style="max-height: 200px !important; "
      >
      </canvas>
      <img
        id="ecg3"
        v-if="imgUrl3"
        style="width: 100%; height: auto;"
        :src="imgUrl3"
        alt=""
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main.js'
import * as cjs from 'chart.js'
import { registerables } from 'chart.js'
import 'chartjs-adapter-moment'

export default {
  name: 'pdfDialog',
  props: ['data'],
  data() {
    return {
      chartData: [],
      myChart: null,
      myChart2: null,
      myChart3: null,
      loading: true,
      imgUrl1: null,
      imgUrl2: null,
      imgUrl3: null
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.loading = true
        this.chartData = this.data
      }
    },

    chartData: function() {
      this.loading = true
      this.fillData()
    },
    loading: function() {
      this.$emit('graphLoading', this.loading)
    }
  },
  created() {
    cjs.Chart.register(...registerables)
  },
  async mounted() {
    this.loading = true
    await this.fillData()
    eventBus.$emit('ecgLoaded')
  },
  methods: {
    done1() {
      this.imgUrl1 = this.myChart.toBase64Image()
    },
    done2() {
      this.imgUrl2 = this.myChart2.toBase64Image()
    },
    done3() {
      this.imgUrl3 = this.myChart3.toBase64Image()
    },
    fillData() {
      this.loading = false
      if (this.myChart) {
        this.myChart.destroy()
      }
      this.myChart = new cjs.Chart(this.$refs.ecg1, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 1',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[0]
            }
          ]
        },
        options: {
          animation: {
            onComplete: this.done1
          },
          responsive: true,
          elements: {
            point: {
              radius: 0
            },
            line: {
              tension: 1
            }
          },
          scales: {
            xAxes: {
              type: 'linear'
            },
            yAxes: {
              display: true,
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: 'Y axis label'
              }
            }
          }
        }
      })

      if (this.myChart2) {
        this.myChart2.destroy()
      }
      this.myChart2 = new cjs.Chart(this.$refs.ecg2, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 2',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[1]
            }
          ]
        },
        options: {
          animation: {
            onComplete: this.done2
          },
          responsive: true,
          elements: {
            point: {
              radius: 0
            },
            line: {
              tension: 1
            }
          },
          scales: {
            xAxes: {
              type: 'linear'
            },
            yAxes: {
              display: true,
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: 'Y axis label'
              }
            }
          }
        }
      })

      if (this.myChart3) {
        this.myChart3.destroy()
      }
      this.myChart3 = new cjs.Chart(this.$refs.ecg3, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Panel 3',
              borderColor: 'red',
              backgroundColor: 'red',
              borderWidth: 1,
              data: this.chartData[2]
            }
          ]
        },
        options: {
          animation: {
            onComplete: this.done3
          },
          responsive: true,
          elements: {
            point: {
              radius: 0
            },
            line: {
              tension: 1
            }
          },
          scales: {
            xAxes: {
              type: 'linear'
            },
            yAxes: {
              display: true,
              ticks: {
                beginAtZero: true
              },
              scaleLabel: {
                display: true,
                labelString: 'Y axis label'
              }
            }
          }
        }
      })
    }
  }
}
</script>
