<template>
  <div>
    <!-- <Yearly :chartData="chartData" /> -->
    <div>
      <v-card flat v-show="selected === 'MONTH'">
        <v-card-title class="pt-0"
          >Pulse Ox
          <v-spacer></v-spacer>
          <v-card-subtitle
            class="mt-0 pt-0 black--text font-weight-bold"
            style="text-align: right;"
          >
            {{ dayCount }} / 30 Days
          </v-card-subtitle>
        </v-card-title>
        <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
          >Month: {{ computedMonth }}</v-card-subtitle
        >
        <v-card-text>
          <Monthly :data="monthlyData">
            <template v-if="monthlyData.length <= 0 || loadingGraph">
              <v-overlay opacity="0" absolute :value="true">
                <v-card-text
                  v-if="monthlyData.length <= 0 && !loadingGraph"
                  class="black--text"
                  >No Data</v-card-text
                >
              </v-overlay>
            </template>
          </Monthly>
        </v-card-text>
      </v-card>

      <v-overlay
        v-if="loadingGraph"
        class="d-flex justify-center align-center"
        opacity="0.4"
        absolute
        :value="true"
      >
        <v-progress-circular indeterminate color="white"></v-progress-circular>
        <span class="ml-4 white--text">Loading Data</span>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import Monthly from './Monthly'

export default {
  name: 'PulseOxMain',
  props: ['patientId', 'uniqueName', 'practiceId', 'selectedDisplay', 'data'],
  components: {
    Monthly
  },
  data() {
    return {
      loadingGraph: false,
      today: new Date(),
      selected: 'MONTH',
      healthData: [],
      dailyData: [],
      weeklyData: [],
      monthlyData: [],
      yearlyData: [],
      dayCount: 0
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        if (this.data) {
          const data = this.data.filter(x => x.type == 'BLOOD_OXYGEN')
          this.healthData = data
        }
      }
    },
    selectedDisplay: {
      immediate: true,
      handler() {
        if (this.selectedDisplay === 'MONTH') {
          this.selected = 'MONTH'
        } else if (this.selectedDisplay === 'WEEK') {
          this.selected = 'WEEK'
        } else if (this.selectedDisplay === 'DAY') {
          this.selected = 'DAY'
        } else if (this.selectedDisplay === 'YEAR') {
          this.selected = 'YEAR'
        }
      }
    },
    healthData: {
      immediate: true,
      handler() {
        this.monthlyData = this.getMonthlyData()
      }
    }
  },

  methods: {
    getMonthlyData() {
      let monthlyData = []

      for (const item of this.healthData) {
        const date = moment(item.date.toDate()).startOf('day')
        const dateU = moment(item.date.toDate()).format('lll')

        monthlyData.push({ x: date, y: item.value, date: dateU })
      }
      this.calculateTotalData(monthlyData)

      return monthlyData
    },

    calculateTotalData(monthlyData) {
      let dayCount = 0
      let last = moment().day()
      monthlyData.sort((a, b) => (a.x > b.x ? 1 : -1))
      for (const item of monthlyData) {
        if (moment(item.x).day() !== last) {
          dayCount++
        }
        last = moment(item.x).day()
      }
      this.dayCount = dayCount
    }
  },
  computed: {
    computedMonth: {
      get() {
        return this.today ? moment(this.today).format('MMMM, YYYY') : ''
      },
      set() {}
    }
  }
}
</script>
