<template>
  <div>
    <div
      v-for="(item, index) in graphsToDisplay"
      :key="index"
      :class="graphsToDisplay.length !== index ? 'html2pdf__page-break' : ''"
    >
      <v-card-subtitle class="mt-0 pt-0 black--text font-weight-bold"
        >Recorded Date: {{ item.date }}
      </v-card-subtitle>

      <v-card-text>
        <pdfDialog :data="item.dailyData" :key="index">
          <template v-if="item.dailyData.length <= 0 || loadingGraph">
            <v-overlay opacity="0" absolute :value="true">
              <v-card-text
                v-if="item.dailyData.length <= 0 && !loadingGraph"
                class="black--text"
                >No Data</v-card-text
              >
            </v-overlay>
          </template>
        </pdfDialog>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import * as fb from '@/firebase'
import moment from 'moment'
import pdfDialog from './pdfDialog'

export default {
  name: 'EcgPdf',
  props: ['patientId', 'uniqueName', 'practiceId'],
  components: {
    pdfDialog
  },
  data() {
    return {
      loadingGraph: true,
      today: new Date(),
      date: new Date(),
      graphs: [],
      graphsToDisplay: []
    }
  },
  watch: {
    graphs: function() {
      this.graphsToDisplay = []

      for (const item of this.graphs) {
        let date = item.date
          ? moment(item.date.toDate()).format('LLLL')
          : moment(new Date()).format('LLLL')
        let allData = []
        let dailyData1 = []
        let dailyData2 = []
        let dailyData3 = []
        let arr = []

        for (let c = 0; c < item.values.length; c++) {
          if (item.values[c] !== null) {
            arr.push({ x: c / 500, y: item.values[c] })
          }
        }

        const len = arr.length / 3

        let chunks = [],
          i = 0,
          n = arr.length
        while (i < n) {
          chunks.push(arr.slice(i, (i += len)))
        }

        const data1 = chunks[0]
        for (let c = 0; c < data1.length; c++) {
          if (data1[c] !== null) {
            dailyData1.push(data1[c])
          }
          i++
        }

        const data2 = chunks[1]
        for (let c = 0; c < data2.length; c++) {
          if (data2[c] !== null) {
            dailyData2.push(data2[c])
          }
          i++
        }

        const data3 = chunks[2]
        for (let c = 0; c < data3.length; c++) {
          if (data3[c] !== null) {
            dailyData3.push(data3[c])
          }
          i++
        }

        allData.push(dailyData1)
        allData.push(dailyData2)
        allData.push(dailyData3)
        this.graphsToDisplay.push({ dailyData: allData, date: date })
      }
    }
  },
  async mounted() {
    this.loadingGraph = true

    await this.$bind(
      'graphs',
      fb.db
        .collection(`Patients/${this.patientId}/HealthData`)
        .where('type', '==', 'ELECTROCARDIOGRAM')
        .orderBy('date', 'desc')
    )
    this.loadingGraph = false
  }
}
</script>
