<template>
  <div>
    <div class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4 mb-2">
      Scanned Medical Documents
    </div>

    <div id="documents">
      <v-row>
        <v-col cols="12" v-for="item in this.allRecordings" :key="item.id">
          <div style="width: 90%;" class="html2pdf__page-break">
            <img :src="item.url" style="width: 100%;" />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import * as fb from '@/firebase'

export default {
  name: 'Documents',
  props: ['patientId', 'uniqueName', 'practiceId'],
  data() {
    return {
      allRecordings: [],
      documents: []
    }
  },
  watch: {
    model: function() {
      this.setType()
    },
    documents: function() {
      this.allRecordings = []
      let count = 0

      for (const item of this.documents) {
        this.allRecordings.push({
          ...item,
          id: item.id,
          index: count,
          type: 'document'
        })

        count++
      }
    }
  },
  mounted() {
    this.$bind(
      'documents',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Medical Documents`
      )
    )
  },
  methods: {}
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
