<template>
  <div>
    <v-card-title>
      HealthData
    </v-card-title>
    <div v-if="showHealthData">
      <v-row>
        <v-col cols="12">
          <div class="html2pdf__page-break">
            <HeartRateMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <v-divider class="my-4"></v-divider>
            <BloodPressureMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
          </div>

          <div class="html2pdf__page-break">
            <BloodGlucoseMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <v-divider class="my-4"></v-divider>
            <PulseOxMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
          </div>
          <div class="html2pdf__page-break">
            <BmiWeightMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
            <v-divider class="my-4"></v-divider>
            <WalkingHeartRateMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
          </div>

          <div class="html2pdf__page-break">
            <CardioFitnessMain
              :patientId="patientId"
              :uniqueName="uniqueName"
              :practiceId="practiceId"
              :selectedDisplay="selectedGraphType.default"
              :data="healthData"
            />
          </div>

          <EcgPdf
            :patientId="patientId"
            :uniqueName="uniqueName"
            :practiceId="practiceId"
          />
        </v-col>
      </v-row>
    </div>
    <v-card-text v-else>{{ sendLoaded('No data available.') }}</v-card-text>
  </div>
</template>
<script>
import { db, auth } from '@/firebase'
import { eventBus } from '@/main.js'
import HeartRateMain from './heartRate/HeartRateMain'
import BloodPressureMain from './bloodPressure/bloodPressureMain'
import BloodGlucoseMain from './bloodGlucose/bloodGlucoseMain'
import PulseOxMain from './pulseOx/pulseOxMain'
import BmiWeightMain from './bmiWeight/bmiWeightMain'
import WalkingHeartRateMain from './walkingHeartRate/WalkingHeartRateMain'
import CardioFitnessMain from './cardioFitness/CardioFitnessMain'

import EcgPdf from './ecg/EcgPdf'

export default {
  name: 'HealthData',
  props: ['patientId', 'uniqueName', 'practiceId'],
  components: {
    HeartRateMain,
    BloodPressureMain,
    BloodGlucoseMain,
    PulseOxMain,
    BmiWeightMain,
    WalkingHeartRateMain,
    CardioFitnessMain,
    EcgPdf
  },
  data() {
    return {
      healthData: [],
      displayIndex: 0,
      hrActive: true,
      showPdf: false,
      graphsLength: 0,
      ecgIndex: 0,
      rand: '123',
      showHealthData: false,
      selectedGraphType: {
        name: 'Heart Rate',
        type: 'HR',
        default: 'MONTH'
      },
      persistantGraph: {
        name: 'Heart Rate',
        type: 'HR',
        default: 'MONTH'
      },
      graphsHeader: [
        {
          text: 'Type',
          align: 'start',
          value: 'name'
        },
        {
          text: '',
          align: 'end',
          value: 'actions'
        }
      ],
      graphs: [],
      authUser: null
    }
  },
  watch: {
    healthData: {
      immediate: true,
      handler() {
        if (this.healthData.length > 0 && this.authUser) {
          this.showHealthData = true
          if (
            !this.authUser.healthData.HR &&
            !this.authUser.healthData.WHR &&
            !this.authUser.healthData.BP &&
            !this.authUser.healthData.BG &&
            !this.authUser.healthData.PO &&
            !this.authUser.healthData.BW &&
            !this.authUser.healthData.CFL &&
            !this.authUser.healthData.ECG
          ) {
            this.showHealthData = false
          }

          if (
            this.healthData.some(x => x.type === 'HEART_RATE') &&
            this.authUser.healthData.HR
          ) {
            this.graphs.push({
              key: 1,
              name: 'Heart Rate',
              type: 'HR',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'WALKING_HEART_RATE') &&
            this.authUser.healthData.WHR
          ) {
            this.graphs.push({
              key: 2,
              name: 'Walking Heart Rate',
              type: 'WHR',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_PRESSURE_SYSTOLIC') &&
            this.authUser.healthData.BP
          ) {
            this.graphs.push({
              key: 3,
              name: 'Blood Pressure',
              type: 'BP',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_GLUCOSE') &&
            this.authUser.healthData.BG
          ) {
            this.graphs.push({
              key: 4,
              name: 'Blood Glucose',
              type: 'BG',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BLOOD_OXYGEN') &&
            this.authUser.healthData.PO
          ) {
            this.graphs.push({
              key: 5,
              name: 'Pulse Ox',
              type: 'PO',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'BODY_MASS_INDEX') &&
            this.authUser.healthData.BW
          ) {
            this.graphs.push({
              key: 6,
              name: 'BMI & Weight',
              type: 'BW',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'CARDIO_FITNESS_LEVEL') &&
            this.authUser.healthData.CFL
          ) {
            this.graphs.push({
              key: 7,
              name: 'Cardio Fitness Level',
              type: 'CFL',
              default: 'MONTH',
              color: ''
            })
          }
          if (
            this.healthData.some(x => x.type === 'ELECTROCARDIOGRAM') &&
            this.authUser.healthData.ECG
          ) {
            this.graphs.push({
              key: 8,
              name: 'ECG',
              type: 'ECG',
              default: 'DAY',
              color: ''
            })
          }
          this.persistantGraph = { ...this.graphs[0] }
        }
      }
    },
    ecgIndex: {
      immediate: true,
      handler() {
        this.displayIndex = this.ecgIndex + 1
      }
    }
  },
  async mounted() {
    const currentUserId = await auth.currentUser.uid
    this.$bind('authUser', db.collection('Proxies').doc(currentUserId))
    this.$bind(
      'healthData',
      await db
        .collection('Patients')
        .doc(this.patientId)
        .collection('HealthData')
    )
  },
  methods: {
    setEcgLength(len) {
      this.graphsLength = len
    },
    nextIndex() {
      this.ecgIndex++
    },
    prevIndex() {
      this.ecgIndex--
    },
    next(val) {
      this.ecgIndex = val
    },
    prev(val) {
      this.ecgIndex = val
    },
    sendLoaded(text) {
      eventBus.$emit('ecgLoaded')
      return text
    },
    viewPdf() {
      var result = ''
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = 3
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      this.rand = result
      this.showPdf = true
    }
  }
}
</script>
