<template>
  <div>
    <div class="font-weight-bold text-md-subtitle-1 text-subtitle-1 mt-4 mb-2">
      Medications
    </div>

    <div id="medications-images">
      <v-row>
        <v-col cols="12" v-for="item in this.photoUpload" :key="item.id">
          <div style="width: 90%;" class="html2pdf__page-break">
            <img :src="item.url" style="width: 100%;" />
          </div>
        </v-col>
      </v-row>
    </div>

    <div id="medications-text" class="html2pdf__page-break">
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headersRecordings"
            :items="textOnly"
            item-key="id"
            class="elevation-0"
            :items-per-page="itemsPerPage"
            hide-default-footer
          ></v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import * as fb from '@/firebase'
export default {
  name: 'Documents',

  props: ['patientId', 'uniqueName', 'practiceId'],
  data() {
    return {
      itemsPerPage: 100,
      selectedDocument: {},
      headersRecordings: [
        {
          text: 'Medication Name',
          align: 'start',
          value: 'fileName'
        }
      ],
      textOnly: [],
      photoUpload: [],
      medications: []
    }
  },
  watch: {
    model: function() {
      this.setType()
    },
    medications: function() {
      let count = 0
      let count2 = 0

      for (const item of this.medications) {
        if (item.url) {
          this.photoUpload.push({
            fileName: item.fileName,
            id: item.id,
            index: count,
            url: item.url,
            type: 'medication'
          })
          count++
        } else {
          this.textOnly.push({
            fileName: item.fileName,
            id: item.id,
            index: count2,
            url: item.url,
            type: 'medication'
          })
          count2++
        }
      }
    }
  },
  mounted() {
    this.$bind(
      'medications',
      fb.db.collection(
        `Practices/${this.practiceId}/Appointments/${this.uniqueName}/Medications`
      )
    )
  }
}
</script>
<style>
audio {
  width: 100%;
}
canvas {
  width: 100%;
}
</style>
